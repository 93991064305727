<template>
  <div class="client_layout">
    <img src="@/assets/client_swiper.png" class="client_swiper" />
    <div class="title">
      <span>入驻客户</span>
      <span>Settled customer</span>
    </div>
    <div class="tips">入驻客户超2万家，这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案本次大赛由湘赣边烟花爆竹产业发展委员会湖南省工艺美术协会烟花爆竹专业</div>
    <div class="logo_list">
      <div class="list_l" />
      
      <div class="list_item" v-for="(item, index) in tList" :key="index">
        <img :src="z.logo" class="logo_icon" v-for="(z, i) in item" :key="i" :style="{ transform: `translateX(${tList.length - index}rem)` }" />
      </div>
      <div class="list_r" />
    </div>
    <div class="scheme_layout">
      <div class="scheme_l">
        <div>企业解决方案</div>
        <div>个性化私人定制</div>
        <div>专业技术团队支持</div>
        <div>战略合作部署</div>
        <div>立即咨询</div>
      </div>
      <div class="scheme_r">
        <div class="z_">
          <div>优势文案</div>
          <div>根据你企业的具体情况进行深度定制。从业务流程的优化到技术平台的搭建，我们将与你紧密合作，确保每一个环节都能精准地满足你企业的发展目标。</div>
        </div>
        <div style="width: 1PX; height: 3.1545rem; background-color: #E8E8E8;" />
        <div class="z_">
          <div>优势文案</div>
          <div>根据你企业的具体情况进行深度定制。从业务流程的优化到技术平台的搭建，我们将与你紧密合作，确保每一个环节都能精准地满足你企业的发展目标。</div>
        </div>
      </div>
    </div>
    <!-- <div class="bottom_layout">
      <div class="main">
        <div class="title">
          <span>服务客户</span>
          <span>Settled customer</span>
        </div>
        <div class="tips">企业建站是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案本次大赛由湘赣边烟花爆竹产业发展委员会湖南省工艺美术协会烟花爆竹专业</div>
        <div class="client_list">
          <img :src="item.logo" class="client_item" v-for="(item, index) in bList" :key="index" />
        </div>
      </div>
    </div> -->
  </div>
</template>

<script lang="ts" setup>
  import { getClientList } from '@/api'
  import { ElMessage } from 'element-plus'
  import { ref } from 'vue'
  
  const tList = ref<Array<object>>([])
  // const bList = ref<Array>([])
    
  const getArticleLists = async type => {
    const { data: res } = await getClientList({}, { page: 1, pageSize: 999, type })
    if (res.code === 1) {
      if (type === 1) {
        const list = [[]]
        res.result.records.forEach((item, index) => {
          if (list[list.length - 1].length >= 6) {
            list[list.length] = [item]
          } else {
            list[list.length - 1].push(item)
          }
        })
        tList.value = list
      }
      // if (type === 2) bList.value = res.result.records
    } else {
      ElMessage.error(res.error && res.error.length ? res.error : '获取失败')
    }
  }
  
  getArticleLists(1)
  // getArticleLists(2)
  </script>

<style lang="scss">
.client_layout {
  .client_swiper {
    width: 100%;
    height: auto;
  }
  .title {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 1.514rem;
    padding: 2.5236rem 0 0;
    margin-bottom: .6309rem;
    >span:nth-child(1) {
      position: relative;
      z-index: 9;
      color: #282938;
    }
    >span:nth-child(2) {
      position: absolute;
      top: 2.5236rem;
      left: 50%;
      transform: translate(-50%, 50%);
      color: #D5D6F3;
      opacity: .6;
    }
  }
  .tips {
    padding: .6309rem 0 1.5772rem;
    font-size: .5678rem;
    color: rgba(40, 41, 56, .6);
    width: 34.3848rem;
    margin: 0 auto;
    line-height: .8517rem;
  }
  .logo_list {
    position: relative;
    min-width: 1200PX;
    max-width: 80%;
    margin: 0 auto 3.7223rem;
    overflow: hidden;
    .list_item {
      display: flex;
      align-items: center;
      width: 7.2555rem;
      white-space: nowrap;
      .logo_icon {
        width: 7.2555rem;
        height: 2.776rem;
        object-fit: contain;
        margin-right: 1.2618rem;
      }
    }
    .list_l,
    .list_r {
      position: absolute;
      top: 0;
      width: 200PX;
      height: 100%;
      z-index: 9;
    }
    .list_l {
      left: 0;
      background: linear-gradient(90deg, #FFFFFF 0%, rgba(255,255,255,0.09) 100%);
    }
    .list_r {
      right: 0;
      background: linear-gradient(to left, #FFFFFF 0%, rgba(255,255,255,0.09) 100%);
    }
  }
  .scheme_layout {
    width: 45.1419rem;
    height: 10.2523rem;
    margin-bottom: 3.15457rem;
    background-image: url('@/assets/client_bg_icon.png');
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    align-items: center;
    .scheme_l {
      margin-left: 15.3943rem;
      color: #fff;
        >div:nth-child(1) {
          font-size: .9463rem;
          margin-bottom: .634rem;
        }
        >div:nth-child(2),
        >div:nth-child(3),
        >div:nth-child(4) {
          display: flex;
          align-items: center;
          font-size: .4416rem;
          margin-bottom: .317rem;
          &::before {
            content: '';
            display: inline-block;
            width: 8PX;
            height: 8PX;
            border-radius: 50%;
            background-color: #fff;
            margin-right: .317rem;
          }
        }
        >div:nth-child(4) {
          margin-bottom: .634rem;
        }
        >div:nth-child(5) {
          display: inline-block;
          margin-bottom: 0;
          padding: .2208rem 1.1987rem;
          font-size: .4416rem;
          border: 1PX solid #fff;
          border-radius: 2PX;
        }
    }
    .scheme_r {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 7.8675rem;
      width: 27.7287rem;
      height: 7.0347rem;
      background-color: #F1F7FF;
      border-radius: 10PX;
      .z_ {
        flex: 1;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        >div:nth-child(1) {
          width: 10.4416rem;
          font-size: .5678rem;
          color: #151D48;
          margin-bottom: .317rem;
          font-weight: 600;
        }
        >div:nth-child(2) {
          width: 10.4416rem;
          color: #737791;
          font-size: .4416rem;
          line-height: .66246rem;
        }
      }
    }
  }
  .bottom_layout {
    padding-bottom: 2.839rem;
    margin-bottom: 2.5236rem;
    background-color: #F4F5FD;
    .main {
      min-width: 1200PX;
      max-width: 80%;
      margin: 0 auto;
      .client_list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .client_item {
          width: 8.328rem;
          height: 2.3974rem;
          margin-right: 1.104rem;
          margin-bottom: .317rem;
        }
      }
    }
  }
}
</style>
