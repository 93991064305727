<template>
  <div class="index_layout">
    <img src="@/assets/index_swiper.png" class="index_swiper" />
    <div class="title">
      <span>我们的产品</span>
      <span>Our Products</span>
    </div>
    <div class="home_tab">
      <div class="tab_item">
        <img src="@/assets/home_icon1.png" alt="" class="home_icon1" />
        <div>公众号微官网</div>
        <div>公众号微官网是基于微信公众号生态搭建的数字化平台，专门为烟花企业提供品牌展示与产品营销解决方案。该平台会为每一款烟花产品生成专属二维码，消费者通过扫码不仅能查看产品详细参数，还能欣赏产品真实燃放的视频，从而全面深入地了解产品特性。</div>
      </div>
      <div class="tab_item">
        <img src="@/assets/home_icon3.png" alt="" class="home_icon3" />
        <div>企业小程序</div>
        <div>这是一个深度整合微官网功能的商城化平台，除了包含微官网所有的功能以外，还基于微信生态打造了"扫码即购"的闭环消费体验，重构烟花行业O2O商业模式。</div>
      </div>
      <div class="tab_item">
        <img src="@/assets/home_icon2.png" alt="" class="home_icon2" />
        <div>花炮云零售店选货系统</div>
        <div>花炮云零售店选货系统是一款专为烟花零售行业打造的数字化选货平台。它整合了全国范围内的烟花货源信息，通过先进的技术手段，为烟花零售店铺、批发商和品牌方提供便捷、高效的选货服务。该系统利用花炮云的附近流量入口，实现线上线下的流量融合，让消费者能够轻松找到附近的零售店，同时也为商家提供了更多的销售机会。</div>
      </div>
    </div>
    <div class="title" style="padding-bottom: 1.735rem;">
      <span>我们的优势</span>
      <span>Our advantages</span>
    </div>
    <div class="layout_item">
      <div class="layout_item_main" style="padding-bottom: 1.987rem;">
        <div class="desc">
          <div class="desc_title">公众号微官网</div>
          <div class="tips_title">核心功能</div>
          <div class="desc_item">1.一站式品牌展示：品牌展示矩阵整合了企业简介、产品图库、燃放视频、在线客服等多个模块，支持VR展厅、3D产品模型、视频弹幕等交互式呈现方式。</div>
          <div class="desc_item">2.智能产品溯源：为每一款产品生成专属二维码，用户扫码即可查看产品效果，包括参数、燃放视频、安全指南等详细信息。</div>
          <div class="desc_item">3.区域市场洞察：实时追踪全国扫码数据，生成城市级消费偏好热力图，辅助企业精准布局区域市场与进行产能规划。</div>
          <div class="tips_title">核心价值</div>
          <div class="desc_item">以轻量化部署构建企业的"微信门户"，强化用户对品牌的认知，增强互动黏性，推动营销转化率提升。</div>
          <div class="tips_title">适用对象</div>
          <div class="desc_item" style="margin-bottom: 0;">适用于注重品牌形象塑造、需要深度运营微信生态的烟花生产企业及品牌商。</div>
        </div>
        <img src="@/assets/index_swiper1.png" class="index_swiper1" />
      </div>
    </div>
    <div class="layout_item">
      <div class="layout_item_main" style="padding: 1.4195rem 0;">
        <img src="@/assets/index_swiper2.png" class="index_swiper2" />
        <div class="desc">
          <div class="desc_title">批发零售厂商，一网打尽</div>
          <div class="tips_title">核心功能</div>
          <div class="desc_item">1.模板定制与模块化选择：根据客户的品牌调性进行一对一的模板定制，实现品牌视觉统一。企业还可根据自身实际需求，模块化选择所需功能，实现个性化定制。</div>
          <div class="desc_item">2.购物与订单提醒：具备购物车功能，方便用户选购商品。用户选择产品下单后，系统会通过短信和语音的方式提醒店主接单备货，确保订单处理的及时性。</div>
          <div class="desc_item">3.优惠券发放：企业可在小程序内发放优惠券，以此刺激用户消费，提高用户的购买意愿。</div>
          <div class="desc_item">4.多端数据同步：与微官网、花炮云实现无缝对接，统一管理用户资产与订单数据，确保数据的一致性和准确性。</div>
          <div class="desc_item">5.流量闭环打造：用户扫描产品二维码后，可找到附近的直营店或合作店，将线上流量有效转化为线下消费，实现流量的闭环管理。</div>
          <div class="desc_item">6.零售端管理：绑定零售端，使用花炮云选货系统生成电子提货单，方便零售店进行货物管理。同时，系统还能统计零售店的销量，为企业的销售策略调整提供数据依据。</div>
          <div class="desc_item">7.多股东分享+智能名片系统：股东/员工绑定专属名片后，通过社交圈快速传播企业信息，同时整合品牌产品资源和客户管理系统，实现精准获客。</div>
          <div class="tips_title">核心价值</div>
          <div class="desc_item">打造“即用即走”的轻应用模式，降低企业的获客成本，同时提升用户的留存率与复购率，为企业创造更多商业价值。</div>
          <div class="tips_title">适用对象</div>
          <div class="desc_item" style="margin-bottom: 0;">面向烟花零售店铺、批发商和品牌方，满足他们在选货、采购、销售等环节的不同需求。</div>
        </div>
      </div>
    </div>
    <div class="layout_item">
      <div class="layout_item_main" style="padding: 1.4195rem 0;">
        <div class="desc">
          <div class="desc_title">花炮云零售店选货系统</div>
          <div class="tips_title">核心功能</div>
          <div class="desc_item">1.全国货源直连：聚合全国40万家零售店的货源信息，消费者只需打开小程序，即可快速找到附近的零售店，获取丰富的产品选择。</div>
          <div class="desc_item">2.消费者选货下单：消费者可在系统内选择所需的烟花产品并下单，下单后系统会通过短信和语音的方式提醒店主接单备货，确保订单能够及时处理。</div>
          <div class="desc_item">3.流量精准输送：花炮云基于大数据优势，精准分析消费者的地理位置和消费偏好，将花炮云的流量精准输送至附近的零售店，有效提高店铺的客流量和销量，助力商家在竞争激烈的市场中脱颖而出。</div>
          <div class="tips_title">核心价值</div>
          <div class="desc_item">利用花炮云的附近流量入口以及高效的选货系统，能够显著提升采购效率30%以上，帮助零售店优化库存管理，从而助力零售店实现利润增长。</div>
          <div class="tips_title">适用对象</div>
          <div class="desc_item" style="margin-bottom: 0;">适用于需要构建私域流量池、优化用户体验的烟花爆竹生产及连锁零售企业。</div>
        </div>
        <img src="@/assets/index_swiper3.png" class="index_swiper3" />
      </div>
    </div>
    <div class="layout_item">
      <div class="layout_item_main" style="padding: 1.4195rem 0;">
        <img src="@/assets/index_swiper4.png" class="index_swiper4" />
        <div class="desc">
          <div class="desc_title">TO 店铺</div>
          <div class="tips_title">双端协同与实时数据管理</div>
          <div class="desc_item">1.跨端实时同步：支持电脑/手机端数据无缝互通，依托云端数据库实现订单、产品资料等核心信息实时同步</div>
          <div class="desc_item">2.极简界面设计：单层级操作面板集成订单管理、产品编辑等高频功能，支持动态更新机制</div>
          <div class="desc_item">3.AI数据采集：通过包装盒拍照智能解析参数，OCR自动识别并录入产品数据，提高上传产品效率</div>
          <div class="desc_item">4.跨平台数据迁移支持 - 支持跨平台数据迁移功能，实时同步至公众号微官网、企业小程序及花炮云平台，确保多端数据同步更新。</div>
        </div>
      </div>
    </div>
    <div class="layout_item">
      <div class="layout_item_main">
        <div class="desc">
          <div class="desc_title">专注于烟花产品的视觉呈现</div>
          <div class="tips_title">烟花视频拍摄</div>
          <div class="desc_item">1.动态素材库构建 - 通过多维度运镜及慢动作特写捕捉烟花绽放细节，建立标准化视觉素材库；</div>
          <div class="desc_item">2.场景化营销赋能 - 精准匹配产品展示、数字营销及渠道推广场景，打造差异化视觉竞争力；</div>
          <div class="desc_item">3.品牌价值升级 - 运用无人机编队光影叙事技术，建立行业级烟花视觉标准，提升品牌溢价空间。</div>
        </div>
        <img src="@/assets/index_swiper5.png" class="index_swiper5" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>

</script>

<style lang="scss">
.index_layout {
  .index_swiper {
    width: 100%;
    height: auto;
  }
  .title {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 1.514rem;
    padding: 2.5236rem 0 3.533rem;
    >span:nth-child(1) {
      position: relative;
      z-index: 9;
      color: #282938;
    }
    >span:nth-child(2) {
      position: absolute;
      top: 2.5236rem;
      left: 50%;
      transform: translate(-50%, 50%);
      color: #D5D6F3;
      opacity: .6;
    }
  }
  .home_tab {
    max-width: 80%;
    min-width: 1200PX;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0 auto;
    .tab_item {
      width: 12.776rem;
      padding: 1.577rem 1.2618rem 0;
      box-sizing: border-box;
      background-color: #F6F4FC;
      color: #282938;
      min-height: 12.5rem;
      .home_icon1,
      .home_icon2,
      .home_icon3 {
        margin-bottom: .82rem;
      }
      .home_icon1 {
        width: 1.1211rem;
      }
      .home_icon2 {
        width: 1.009rem;
      }
      .home_icon3 {
        width: 1.1211rem;
      }
      >div:nth-child(2) {
        font-size: 0.757rem;
        margin-bottom: .378rem;
      }
      >div:nth-child(3) {
        font-size: 0.5047rem;
        line-height: .8832rem;
      }
    }
  }
  .layout_item {
    width: 100%;
    min-width: 1200PX;
    box-sizing: border-box;
    .layout_item_main {
      box-sizing: border-box;
      max-width: 80%;
      min-width: 1200PX;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 auto;
      .index_swiper1 {
        width: 27.41324rem;
        height: auto;
      }
      .index_swiper2 {
        width: 24.858rem;
        height: auto;
      }
      .index_swiper3 {
        width: 22.3028rem;
        height: auto;
      }
      .index_swiper4 {
        width: 25.01577rem;
        height: auto;
      }
      .index_swiper5 {
        width: 28.95899rem;
        height: auto;
      }
      .desc {
        color: #151D48;
        .tips_title {
          font-size: .5678rem;
          margin-bottom: .317rem;
        }
        .desc_title {
          font-size: 1.1987rem;
          font-weight: 600;        
          margin-bottom: 1.2618rem;
        }
        .desc_item {
          display: flex;
          align-items: center;
          font-size: .5678rem;
          color: rgba(21, 29, 72, .6);
          margin-bottom: .317rem;
          &::before {
            content: '';
            display: inline-block;
            width: 8PX;
            height: 8PX;
            min-width: 8PX;
            border-radius: 50%;
            background-color: #5D5FEF;
            margin-right: .317rem;
          }
        }
        // >div:nth-child(5) {
        //   margin-bottom: 0;
        // }
      }
    }
    &:nth-child(2n + 2) {
      background-color: #F4F6FC;
      .layout_item_main {
        .desc {
          flex: 1;
          margin-left: 2.2713rem;
        }
      }
    }
  }
}
</style>
